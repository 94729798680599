import "core-js/modules/es.array.push.js";
// import { orderCheck } from '../../../api/payment.js'
import { orderDetail } from '../../../api/order.js';
import EditAddressPopup from '../../common/EditAddressPopup.vue';
import {
// Toast,
Icon, Button } from 'vant';
export default {
  name: 'PayResult',
  components: {
    EditAddressPopup,
    // [Toast.name]: Toast,
    [Icon.name]: Icon,
    [Button.name]: Button
  },
  data() {
    return {
      // 修改地址弹窗是否展示
      editAddressFlag: false,
      // 给修改地址弹窗子组件的传值
      oldAddress: {},
      // payStatus: 0,
      orderId: this.$route.query.orderId,
      addressInfo: {}
      // success: false
    };
  },
  created() {
    // this.getDateLoop() // 开始轮询
    // this.whileChecking()
    this.getOrderPayResult();
  },
  // destroyed() {
  //   clearInterval(window.timer) // 结束轮询
  // },
  methods: {
    goToOrderDetail() {
      this.$router.push({
        path: '/order/detail',
        query: {
          orderId: this.orderId
        }
      });
    },
    editOrderAddress() {
      this.editAddressFlag = true;
    },
    // 确定修改地址回调
    callbackForEdit() {
      this.editAddressFlag = false;
      const orderId = {
        orderId: this.orderId
      };
      orderDetail(orderId).then(res => {
        this.addressInfo = res.data.data.addressInfo;
        this.oldAddress = res.data.data.addressInfo;
      });
    },
    // 取消修改地址回调
    callbackForCancelEdit() {
      this.editAddressFlag = false;
    },
    getDateLoop(timeout = 1000) {
      // timeout可以写死，也可以动态
      window.timer = setInterval(() => {
        setTimeout(this.getOrderPayResult, 0);
        this.whileChecking();
      }, timeout);
    },
    getOrderPayResult() {
      // console.log('getOrderPayResult')
      const orderId = {
        orderId: this.orderId
      };
      if (this.addressInfo.consignee === undefined) {
        orderDetail(orderId).then(res => {
          this.addressInfo = res.data.data.addressInfo;
          this.payStatus = res.data.data.payStatus;
          this.oldAddress = res.data.data.addressInfo;
        });
      }

      // orderCheck(orderId).then(res => {
      //   if (res.data.data.isPaid === true) {
      //     this.isPaid()
      //   }
      // })
    }

    // isPaid() {
    //   this.success = true
    //   clearInterval(window.timer)
    // },

    // whileChecking() {
    //   if (this.success === false) {
    //     Toast.loading({
    //       message: 'check result',
    //       forbidClick: true,
    //       loadingType: 'spinner'
    //     })
    //   }
    // }
  },
  beforeRouteLeave(to, from, next) {
    // 路由跳转前，清除轮询
    next();
    clearInterval(window.timer); // 结束轮询
  }
};